import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Element } from "react-scroll";
import colors from "../../../data/colors.data";

import icons from "../../../assets/images/svgs/icons";

import { HeaderSocials, HeaderToggle } from "./components";

import links from "./config/links.json";

const Header = () => {
  const [isNavigationActive, setIsNavigationActive] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [currentLogo, setCurrentLogo] = useState(icons.logo3);

  const location = useLocation();
  const currentPath = location.pathname.endsWith("/")
    ? location.pathname
    : location.pathname + "/";

  const toggleNavigation = () => {
    setIsNavigationActive(!isNavigationActive);
  };

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 768) {
        setIsMenu(true);
      } else {
        setIsMenu(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenu]);
  useEffect(() => {
    const logos = [icons.logo3, icons.logo4, icons.logo7, icons.logo8large];
    setCurrentLogo(logos[Math.floor(Math.random() * logos.length)]);
  }, []);
  useEffect(() => {
    if (location) {
      return;
    }
  }, [location])
  return (
    <>
      <Element name="header">
        <header className="header" id="header">
          <div className="header-logo">
            <Link to="/" title="Go to home page" aria-label="Go to home page">
              <img src={currentLogo} alt="Code End Tag Logo" loading="lazy" />
            </Link>
          </div>
          <ul
            className={`header-navigation ${
              isNavigationActive ? "active" : ""
            }`}
          >
            {links.all_pages.map((data, key) => (
              <li className="navigation-item" key={key}>
                <a
                  href={data.path}
                  className="item-link"
                  style={{
                    color: `${
                      currentPath === data.path || location.pathname === data.path ? colors.primary : "default"
                    }`,
                  }}
                  title="Go to Home"
                  aria-label="Go to Home"
                >
                  {data.displayText}
                </a>
              </li>
            ))}
          </ul>
          <HeaderSocials
            linkedinRoundWhite={icons.linkedinRoundWhite}
            github={icons.githubMarkWhite}
          />
          <HeaderToggle toggleNavigation={toggleNavigation} menuIcon={icons.menu} />
        </header>
      </Element>
    </>
  );
};

export default Header;

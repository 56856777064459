import React from "react";
import PropTypes from "prop-types";

const HeaderToggle = ({ toggleNavigation, menuIcon }) => {
  return (
    <div className="header-toggle">
      <button className="toggle-button" onClick={toggleNavigation}>
        <img src={menuIcon} alt="Hamburger Menu Icon" loading="lazy" />
      </button>
    </div>
  );
};

HeaderToggle.propTypes = {
  toggleNavigation: PropTypes.func,
  menuIcon: PropTypes.node,
};

export default HeaderToggle;

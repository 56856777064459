const iconsArray = [
  "menu",
  "code",
  "css-3",
  "html-5",
  "javascript",
  "scss2",
  "react",
  "netlify",
  "microsoft",
  "powerpoint",
  "visual-studio-code",
  "word",
  "windows-applications",
  "google-analytics",
  "excel",
  "nodejs-icon",
  "npm",
  "json",
  "download-white",
  "github-mark-white",
  "linkedin",
  "linkedin-icon",
  "linkedin-round",
  "linkedin-round-white",
  "electron",
  "nothing-new",
  "internet",
  "tor",
  "check-bold",
  "x-bold",
  "arrow-sm-right",
  "bash-icon",
  "powershell",
  "google-cloud",
  "figma",
  "stackoverflow",
  "linux",
  "firebase",
  "logo3",
  "logo4",
  "logo7",
  "logo8large",
];

const icons = iconsArray.reduce((acc, name) => {
  acc[name.replace(/-./g, (x) => x[1].toUpperCase())] = require(`./${name}.svg`);
  return acc;
}, {});

export default icons;

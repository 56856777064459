import React from "react";
import PropTypes from "prop-types";

const HeaderSocials = ({ linkedinRoundWhite, github }) => {
  return (
    <div className="header-socials">
      <a
        href="https://www.linkedin.com/in/caden-finkelstein-67239528b/"
        target="_blank"
        rel="noopen noreferrer"
        title="My LinkedIn Page"
        aria-label="Go to LinkedIn"
      >
        <img
          src={linkedinRoundWhite}
          id="LinkedIn"
          alt="LinkedIn"
          loading="lazy"
        />
      </a>
      <a
        href="https://github.com/MysticalMike60t"
        target="_blank"
        rel="noopen noreferrer"
        title="My GitHub Page"
        aria-label="Go to GitHub"
      >
        <img src={github} id="Github" alt="Github" loading="lazy" />
      </a>
    </div>
  );
};

HeaderSocials.propTypes = {
  linkedinRoundWhite: PropTypes.node,
  github: PropTypes.node,
};

export default HeaderSocials;

import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import UserAgentDisplay from "./UserAgentDisplay";

const Layout = () => {
  return (
    <>
      <Header />
      <UserAgentDisplay />
      <div className="container">
        <Outlet />
      </div>
    </>
  );
};

export default Layout;

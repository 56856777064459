import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "../../styles/scss/global/uni.scss";
import * as Global from "../router/global.router";
import * as Pages from "../router/pages.router";
import endpoints from "../../data/endpoints.data";

function App() {
  return (
    <Router>
      <Suspense fallback={<Global.Loading />}>
        <Routes>
          <Route path="/" element={<Global.Layout />}>
            <Route
              index
              element={
                <Suspense fallback={<Global.Loading />}>
                  <Pages.Home />
                </Suspense>
              }
            />
            <Route path="api">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Api />
                  </Suspense>
                }
              />
              <Route
                path="quill-cheat"
                element={
                  <Suspense fallback={<Global.Loading />}>
                    {Pages.api.quillCheat}
                  </Suspense>
                }
              />
            </Route>
            <Route path="downloads">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Downloads />
                  </Suspense>
                }
              />
              <Route
                path="os"
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.OsDownloads />
                  </Suspense>
                }
              />
            </Route>
            <Route path="modding">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Modding />
                  </Suspense>
                }
              />
              <Route path="spt">
                <Route
                  index
                  element={
                    <Suspense fallback={<Global.Loading />}>
                      <Pages.Spt />
                    </Suspense>
                  }
                />
                <Route
                  path="mod-packs"
                  element={
                    <Suspense fallback={<Global.Loading />}>
                      <Pages.ModPacks />
                    </Suspense>
                  }
                />
                <Route
                  path="resources"
                  element={
                    <Suspense fallback={<Global.Loading />}>
                      <Pages.Resources />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path="ads">
              <Route index element={<Pages.Ads />} />
            </Route>
            <Route path="press">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Press />
                  </Suspense>
                }
              />
            </Route>
            <Route path="github">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Github />
                  </Suspense>
                }
              />
              <Route
                path="auth"
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.GithubAuth />
                  </Suspense>
                }
              />
            </Route>
            <Route path="auth">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Auth />
                  </Suspense>
                }
              />
            </Route>
            <Route path="dev">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Dev />
                  </Suspense>
                }
              />
              <Route path="local">
                <Route
                  index
                  element={
                    <Suspense fallback={<Global.Loading />}>
                      <Pages.DevLocal />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="loader" element={<Global.LayoutLinks />}>
                <Route index element={<Global.Loading />} />
              </Route>
            </Route>
            <Route path="art">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Art />
                  </Suspense>
                }
              />
              <Route path="class-works">
                <Route
                  index
                  element={
                    <Suspense fallback={<Global.Loading />}>
                      <Pages.ClassWorks />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
            <Route path="search">
              <Route
                index
                element={
                  <Suspense fallback={<Global.Loading />}>
                    <Pages.Search />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="Q2FkZW4gRmlua2Vsc3RlaW4"
              element={<Pages.Q2FkZW4gRmlua2Vsc3RlaW4 />}
            />
            <Route path="*" element={<Global.LayoutLinks />}>
              <Route path="*" element={<Global.NoPage />} />
            </Route>

            {/* Temporary Route */}
            {/* Content stays until it can be put somewhere else */}
            <Route path="/developer">
              <Route path="/developer/cdn">
                <Route
                  index
                  element={
                    <div className="global-general-page-list no-ul">
                      <a
                        href={endpoints.downloads.main.domain}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {endpoints.downloads.main.domain}
                      </a>
                    </div>
                  }
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
